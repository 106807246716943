import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import coverImageCam from "../../images/coverImageCam.png";
import speakerPhotCamera from "../../images/speakerPhotCamera.png";
import fbIcon from "../../images/fb.png";
import inIcon from "../../images/instagram.png";
import twtIcon from "../../images/twitter.png";
import youtubeIcon from "../../images/youtube.png";
import profileImageUpload from "../../images/profile.jpg";
import "react-quill/dist/quill.snow.css";
import { getData, postData, putData } from "../../../../../services/data";
import { setSpeakers } from "../../../../../store/slices/speaker";
import { showError } from "../../../../../helpers/toast";

const SpeakerAdditionForm = ({ togglePage, speakerToEdit }) => {
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [oldBackgroundImage, setOldBackgroundImage] = useState(
    speakerToEdit?.coverPhoto
  );
  const [oldProfileImage, setOldProfileImage] = useState(
    speakerToEdit?.profilePicture
  );
  const [profileImage, setProfileImage] = useState(null);
  const [speakerName, setSpeakerName] = useState(speakerToEdit?.name ?? "");
  const [speakerDesignation, setSpeakerDesignation] = useState(
    speakerToEdit?.designation ?? ""
  );
  const [speakerCategory, setSpeakerCategory] = useState(
    speakerToEdit?.category ?? ""
  );
  const [facebookUrl, setFacebookUrl] = useState(speakerToEdit?.facebook ?? "");
  const [websiteUrl, setWebsiteUrl] = useState(speakerToEdit?.website ?? "");
  const [twitterUrl, setTwitterUrl] = useState(speakerToEdit?.twitter ?? "");
  const [instagramUrl, setInstagramUrl] = useState(
    speakerToEdit?.instagram ?? ""
  );
  const [youtubeUrl, setYoutubeUrl] = useState(speakerToEdit?.youtube ?? "");
  const [policyContent, setPolicyContent] = useState(
    speakerToEdit?.about ?? ""
  );

  const [processingAJAX, setProcessingAJAX] = useState(false);

  const handlePolicyChange = (content) => {
    setPolicyContent(content);
  };

  const onDropBackgroundImage = (acceptedFiles) => {
    setBackgroundImage(acceptedFiles[0]);
  };

  const onDropProfileImage = (acceptedFiles) => {
    setProfileImage(acceptedFiles[0]);
  };

  const {
    getRootProps: getRootPropsBackground,
    getInputProps: getInputPropsBackground,
  } = useDropzone({
    accept: "image/*",
    onDrop: onDropBackgroundImage,
  });

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
  } = useDropzone({
    accept: "image/*",
    onDrop: onDropProfileImage,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setProcessingAJAX(true);
    if (
      speakerName === "" ||
      speakerDesignation === "" ||
      speakerCategory === "" ||
      policyContent === ""
    ) {
      showError("Fields are Empty");
      setProcessingAJAX(false);
      return false;
    } else {
      const formData = new FormData();
      formData.append("name", speakerName);
      formData.append("designation", speakerDesignation);
      formData.append("category", speakerCategory);
      formData.append("website", websiteUrl);
      formData.append("facebook", facebookUrl);
      formData.append("twitter", twitterUrl);
      formData.append("instagram", instagramUrl);
      formData.append("youtube", youtubeUrl);
      formData.append("about", policyContent);
      if (profileImage) formData.append("profilePicture", profileImage);
      if (backgroundImage) formData.append("coverPhoto", backgroundImage);

      if (speakerToEdit?.id) {
        putData("speakers/" + speakerToEdit.id, undefined, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          getData("speakers", setSpeakers);
          setProcessingAJAX(false);
          togglePage();
        });
      } else {
        postData("speakers", undefined, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          getData("speakers", setSpeakers);
          setProcessingAJAX(false);
          togglePage();
        });
      }
    }
  };

  return (
    <div>
      <div className="addSpeaker__block_header">
        <h2>{speakerToEdit ? "Edit Speakers" : "Add Speakers"}</h2>
      </div>
      <div className="addSpeaker_block_body">
        <form onSubmit={handleSubmit} className="addSpeaker_form_main">
          <div className="addSpeaker_form_coverImage">
            <label className="addSpeaker_form_coverImage_label">
              <div {...getRootPropsBackground()} className="">
                <input {...getInputPropsBackground()} />
                {oldBackgroundImage ? (
                  <img
                    className="list__image_url"
                    src={
                      process.env.REACT_APP_IMAGE_URL + "/" + oldBackgroundImage
                    }
                    alt="Background"
                  />
                ) : (
                  backgroundImage && (
                    <img
                      className="list__image_url"
                      src={URL.createObjectURL(backgroundImage)}
                      alt="Background"
                    />
                  )
                )}
                <div className="addSpeaker_form_UploadcoverImage">
                  <img src={coverImageCam} alt="" />
                  Upload Cover
                </div>
              </div>
            </label>
          </div>
          <div className="addSpeaker_form_profileImage">
            <label className="addSpeaker_form_profileImage_label">
              <div
                {...getRootPropsProfile()}
                className="addSpeaker_form_profileImage_wrap"
              >
                <input {...getInputPropsProfile()} />
                {oldProfileImage ? (
                  <img
                    className="list__image_url"
                    src={process.env.REACT_APP_IMAGE_URL + "/" + oldProfileImage}
                    alt="Profile"
                  />
                ) : profileImage ? (
                  <img
                    className="list__image_url"
                    src={URL.createObjectURL(profileImage)}
                    alt="Profile"
                  />
                ) : (
                  <img
                    className="list__image_url"
                    src={profileImageUpload}
                    alt="Profile"
                  />
                )}
                <div className="addSpeaker_form_UploadprofileImage">
                  <img src={speakerPhotCamera} alt="" />
                </div>
              </div>
            </label>
            <div className="addSpeaker_form_profileImage_heading">
              Upload Speaker Photo
            </div>
          </div>
          <div className="addSpeaker_form_fields">
            <div className="addSpeaker_form_leftCol_field">
              <div className="addSpeaker_form_form_field">
                <label>Speaker Name</label>
                <input
                  type="text"
                  placeholder="Speaker Name"
                  value={speakerName}
                  onChange={(e) => setSpeakerName(e.target.value)}
                />
              </div>
              <div className="addSpeaker_form_form_field">
                <label>Speaker Designation</label>
                <input
                  type="text"
                  placeholder="Speaker Designation"
                  value={speakerDesignation}
                  onChange={(e) => setSpeakerDesignation(e.target.value)}
                />
              </div>
              <div className="addSpeaker_form_form_field">
                <label>Speaker Category</label>

                <input
                  type="text"
                  placeholder="Speaker Category"
                  value={speakerCategory}
                  onChange={(e) => setSpeakerCategory(e.target.value)}
                />
              </div>
              <div className="addSpeaker_form_form_field">
                <label>Website</label>
                <input
                  type="text"
                  placeholder="Website Url"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                />
              </div>
              <div className="addSpeaker_form_form_field_social">
                <label>Facebook</label>
                <div className="addSpeaker_form_form_field_social_field_wrap">
                  <img src={fbIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Facebook URL"
                    value={facebookUrl}
                    onChange={(e) => setFacebookUrl(e.target.value)}
                  />
                </div>
              </div>
              <div className="addSpeaker_form_form_field_social">
                <label>Twitter</label>
                <div className="addSpeaker_form_form_field_social_field_wrap">
                  <img src={twtIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Twitter URL"
                    value={twitterUrl}
                    onChange={(e) => setTwitterUrl(e.target.value)}
                  />
                </div>
              </div>
              <div className="addSpeaker_form_form_field_social">
                <label>Instagram</label>
                <div className="addSpeaker_form_form_field_social_field_wrap">
                  <img src={inIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Instagram URL"
                    value={instagramUrl}
                    onChange={(e) => setInstagramUrl(e.target.value)}
                  />
                </div>
              </div>
              <div className="addSpeaker_form_form_field_social">
                <label>YouTube</label>
                <div className="addSpeaker_form_form_field_social_field_wrap">
                  <img src={youtubeIcon} alt="" />
                  <input
                    type="text"
                    placeholder="YouTube URL"
                    value={youtubeUrl}
                    onChange={(e) => setYoutubeUrl(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="addSpeaker_form_right_field">
              <h3>About Speaker</h3>
              <ReactQuill
                value={policyContent}
                onChange={handlePolicyChange}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
              />
            </div>
          </div>
          <div className="addSpeaker_form_footer">
            <button
              onClick={togglePage}
              className="addSpeaker_form_footer_reset"
              type="reset"
            >
              Cancel
            </button>
            {speakerToEdit === null ||
            speakerToEdit === undefined ||
            speakerToEdit === "" ? (
              <button
                className={`addSpeaker_form_footer_submit ${
                  processingAJAX ? "diabledButton" : ""
                }`}
                type="submit"
              >
                {processingAJAX && speakerToEdit ? "Submitting" : "Submit"}
              </button>
            ) : (
              <button
                className={`addSpeaker_form_footer_submit ${
                  processingAJAX ? "diabledButton" : ""
                }`}
                type="submit"
              >
                {processingAJAX && speakerToEdit ? "Updating" : "Update"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SpeakerAdditionForm;
