// OrderDetails.js
import React from 'react';
import profileImage from '../header/profile.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import HeaderInner from '../cashheader/headerInner';
import Sidebar from '../leftsidebar/sidebar';

const CashDetail = () => {
    const order = useSelector((state) => state.cart);
    const events = useSelector(state => state.event.data);
    const user = useSelector(state => state.user.data);
    const navigate = useNavigate();
    return (
        <div className="cash__vander_main">
            <HeaderInner />
            <Sidebar />
            <div className="content-container drawer-container"></div>
            <div className="page__container_wrapper">
                <div className='order__detaials_main_wrap'>
                    <div className='order__detaials_header'><h2><span onClick={() => navigate('/cash')}>←</span>Order Details</h2></div>
                    <div className="order-details">
                        <div className='user__orderDetails_wrap'>
                            <div className='user__detail_col_box'>
                                <div className='user_img_wrap'>
                                    <img className="profile-picture" src={order.customer.profilePicture ? process.env.REACT_APP_IMAGE_URL + '/' + order.customer.profilePicture : profileImage} alt="pic" />
                                </div>

                                <ul className='user__orderDetails_list'>
                                    {/* <li>Order ID: <strong>{order.orderId}</strong></li> */}
                                    <li>Order Date: <strong>{new Date(Date.now()).toDateString()}</strong></li>
                                    <li>Customer Name: <strong>{order.customer.first_name + ' ' + order.customer.last_name}</strong></li>
                                    <li>Phone Number: <strong>{order.customer.phone}</strong></li>
                                    <li>No. Of Tickets: <strong>{order.items.length}</strong></li>
                                    {/* <li>Gateway: <strong>{order.paymentGateway}</strong></li> */}
                                    <li>Event Name: <strong>{events.filter(e => e.id == order.eventId)[0]?.name}</strong></li>
                                    <li>Cash Vendor: <strong>{user.first_name + ' ' + user.last_name}</strong></li>
                                </ul>
                            </div>
                            <div className='printInvoice__btn'>Print Invoice</div>
                        </div>
                        <div className='tickets__details_wrap'>
                            <div className='header__main_box'><h2>Ticket Details</h2> <div className='cancelBtn_ticket'>Cancel Order</div></div>

                            <table >
                                <thead>
                                    <th><strong>Ticket No:</strong></th>
                                    <th><strong>Type:</strong></th>
                                    <th><strong>Section:</strong></th>
                                    <th><strong>Row:</strong></th>
                                    <th><strong>Seat:</strong></th>
                                    <th><strong>Subtotal:</strong></th>
                                    {/* <th><strong>Discount:</strong></th> */}
                                    <th><strong>VAT:</strong></th>
                                    <th> <strong>Total:</strong></th>
                                </thead>
                                <tbody>
                                    {order.items.map((ticket, index) => (
                                        <tr key={index}>
                                            <td>{ticket.ticket}</td>
                                            <td>{ticket.type}</td>
                                            <td>{ticket.section}</td>
                                            <td>{ticket.row}</td>
                                            <td>{ticket.seat}</td>
                                            <td>{ticket.price}</td>
                                            {/* <td>{ticket.discount}</td> */}
                                            <td>{order.eventVat}%</td>
                                            <td> {(parseFloat(ticket.price) + parseFloat(order.eventVat) / 100).toFixed(2)}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                            <div className='ticket__detail_footer'>
                                <div className='btn_box_col'>
                                    <div className='btn_box_btn_print_ticket'>Print Tickets</div>
                                    <div className='btn_box_btn_resend_ticket'>Resend Ticket</div>
                                </div>
                                <div className='total__cal_col'>
                                    <ul>
                                        <li>Subtotal <strong>{order.currency}{parseFloat(order.subTotal).toFixed(2)}</strong></li>
                                        <li>Discount <strong>{order.currency}{parseFloat(order.discount_amount).toFixed(2)}</strong></li>
                                        <li>VAT <strong>{order.currency}{parseFloat(order.vat).toFixed(2)}</strong></li>
                                        <li>Total Amount <strong>{order.currency}{parseFloat(order.subTotal + order.vat - order.discount_amount).toFixed(2)}</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashDetail;
