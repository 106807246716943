import React, { useEffect, useState } from 'react';
import './livefeed.css';
import { getData, postData } from '../../../services/data';
import { showSuccess } from '../../../helpers/toast';
import { jsonToFormData } from '../../../helpers/utils';
import { useDropzone } from 'react-dropzone';

const LiveFeed = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [location, setLocation] = useState('');
  const [oldThumbnail, setOldThumbnail] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    getData('live').then((res) => {
      updateLiveData(res.data);
    })
  }, []);

  const updateLiveData = (data) => {
    setTitle(data?.title);
    setUrl(data?.url);
    setLocation(data?.location);
    setOldThumbnail(data?.thumbnail);
    setIsLive(data?.title?.length > 0);
  }

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1 && acceptedFiles[0].type.startsWith('image/')) {
      setThumbnail(acceptedFiles[0]);
    } else {
      console.log('Please upload a single image file.');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const handleGoLiveClick = () => {
    if (isLive) {
      postData('live', undefined, jsonToFormData({
        title: '',
        url: '',
        location: '',
        thumbnail: undefined,
      })).then(res => {
        if (res.status === 200) {
          showSuccess(res.data.msg);
          setIsLive(!isLive);
          onClose();
          getData('live').then((res) => {
            updateLiveData(res.data);
          })
        }
      });
    } else {
      let formData = jsonToFormData({
        title: title,
        url: url,
        location: location,
        thumbnail: thumbnail,
      });
      postData('live', undefined, formData).then(res => {
        if (res.status === 200) {
          showSuccess(res.data.msg);
          setIsLive(!isLive);
          onClose();
          getData('live').then((res) => {
            updateLiveData(res.data);
          })
        }
      });
    }

    setIsLive(!isLive);
  };




  return (
    <div className="popup-background live__feed_pop">
      <div className="popup-content">
        <h2>Live Feed</h2>

        <div className='live__feed_pop_first_list'>

          <div className='live__feed_pop_content_left__col'>

            <div className="input-row">
              <label>Title:</label>
              <input
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="input-row">
              <label>URL:</label>
              <input
                type="text"
                placeholder="Enter URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>

          <div className="live__feed_pop_content_input_row">
            <label>Video Thumbnail:</label>
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              {thumbnail ? (
                <div className="event__creation__eventphoto_upload_file_image_uploaded">
                  <img className='file__img_uploaded' src={oldThumbnail ? process.env.REACT_APP_IMAGE_URL + oldThumbnail : URL.createObjectURL(thumbnail)} alt="Thumbnail" />
                </div>
              ) : (
                <div className="event__creation__eventphoto_upload_file_upload">
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="input-row">
          <label>Location:</label>
          <input
            type="text"
            placeholder="Enter location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>


        <div className="buttonrow_live__feed_pop">
          <button className="live__feed_pop_cancel_button" onClick={onClose}>Cancel</button>
          <button className="live__feed_pop_submit_button" onClick={handleGoLiveClick}>{isLive ? 'Stop' : 'Go Live'}</button>
        </div>
      </div>
    </div>
  );
};

export default LiveFeed;
