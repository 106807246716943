import React, { useEffect, useState } from "react";
import "./cardnav.css";
import Calendar from "./images/calendar.png";
import Location from "./images/location.png";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import RoleBasedComponent from "../../utils/rolebasedcomponent";
import { useSelector } from "react-redux";
import { getData } from "../../../services/data";

const CardNav = ({ events }) => {
  const [userWithRoles, setUserWithRoles] = useState({});
  const userDetails = useSelector((state) => state.user.data);
  useEffect(() => {
    getData("users/get_user/" + userDetails?.id).then((res) => {
      setUserWithRoles(res.data);
    });
  }, [userDetails]);

  const navigate = useNavigate();
  const redirection = () => {
    navigate({
      pathname: "/eventcreation",
    });
  };
  return (
    <div className="cardnav-wrapper ">
      <RoleBasedComponent role="create_events" user={userWithRoles}>
        <div className="create-event">
          <div className="createvent__btn" onClick={() => redirection()}>
            <i className="fa-solid fa-plus"></i>
            <span>Create Event</span>
          </div>
        </div>
      </RoleBasedComponent>
      <h5 className="event-heading">Upcoming Events</h5>

      <div className="eventCard__main_wrap">
        {events?.map((event) =>
          new Date(event?.date_from) > new Date() ? (
            <div className="eventCard__card_col">
              <div className="eventCard__card_image">
                {event?.event_images?.length > 0 && (
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "/" +
                      event.event_images[0]?.file_name
                    }
                    className="card-img-top image"
                    alt="..."
                  />
                )}
              </div>
              <div className="eventCard__card_body">
                <h5 className="eventCard__card_event_cat">{event.type}</h5>
                <p className="eventCard__card_event_title">{event.name}</p>
                <div className="card_event_date_location_wrap">
                  <div className="card_event_date_col">
                    <img src={Calendar} alt="-" />
                    <div className="card_event_date_details">
                      <span className="card_event_date_details_day">
                        {format(new Date(event.date_from), "EEEE")}
                      </span>
                      <span className="card_event_date_details_date">
                        {format(new Date(event.date_from), "LLL do, yyyy")}
                      </span>
                    </div>
                  </div>
                  <div className="card_event_location_col">
                    <img src={Location} alt="-" />
                    <div className="card_event_location_details">
                      <span className="card_event_location_title">
                        Location
                      </span>
                      <span className="card_event_location_vanue">
                        {event.venue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  );
};

export default CardNav;
