import React, { useState, useEffect, useRef } from "react";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import "./financetrack.css";
import Arrow from "./arrow.png";
import Ticket from "./ticket.jpg";
import Person from "./person.png";
import { getData } from "../../../services/data";
import { setFinanceTracks } from "../../../store/slices/finance";
import { useSelector } from "react-redux";
import ModalImage from "react-modal-image";

const FinanceTrack = () => {
  const [search, setSearch] = useState("");
  const [financeUser, setFinanceUser] = useState("All");
  const [showDetailsFinance, setShowDetails] = useState(false);
  const [userID, setuserID] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [detailstartDateFilter, setdetailStartDateFilter] = useState(null);
  const [detailendDateFilter, setdetailEndDateFilter] = useState(null);

  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  const [showOptions, setShowOptions] = useState(false);
  const userAdjustedData = useRef();
  const financeTracks = useSelector((state) => state.finance.data);

  useEffect(() => {
    let route = `users/finance_users`;
    if (startDateFilter && endDateFilter) {
      route =
        route + `?start_date=${startDateFilter}&end_date=${endDateFilter}`;
    } else if (startDateFilter && !endDateFilter) {
      route = route + `?start_date=${startDateFilter}`;
    } else if (!startDateFilter && endDateFilter) {
      route = route + `?end_date=${endDateFilter}`;
    }
    if (search && search != "") {
      route = route + `${route.includes("?") ? "&" : "?"}search=${search}`;
    }
    getData(route, setFinanceTracks);
  }, [startDateFilter, search, endDateFilter]);

  useEffect(() => {
    if (!userID) return;
    let route = `adjustments/${userID}`;
    if (detailstartDateFilter && detailendDateFilter) {
      route =
        route +
        `?start_date=${detailstartDateFilter}&end_date=${detailendDateFilter}`;
    } else if (detailstartDateFilter && !detailendDateFilter) {
      route = route + `?start_date=${detailstartDateFilter}`;
    } else if (!detailstartDateFilter && detailendDateFilter) {
      route = route + `?end_date=${detailendDateFilter}`;
    } else if (startDateFilter && endDateFilter) {
      route =
        route + `?start_date=${startDateFilter}&end_date=${endDateFilter}`;
    } else if (startDateFilter && !endDateFilter) {
      route = route + `?start_date=${startDateFilter}`;
    } else if (!startDateFilter && endDateFilter) {
      route = route + `?end_date=${endDateFilter}`;
    }
    getData(route).then((res) => {
      userAdjustedData.current = res.data;
      setSelectedUserDetails(res.data);
    });
  }, [userID, detailstartDateFilter, detailendDateFilter]);

  const toggleOptions = (index) => {
    setShowOptions(!showOptions);
  };

  const toggleDetails = async (userDetails) => {
    setuserID(userDetails.id);
    setShowDetails(!showDetailsFinance);
  };

  const toggleToList = () => {
    setShowDetails(!showDetailsFinance);
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />

        {showDetailsFinance ? (
          <div className="page__container_wrapper ticket__page finance-track-details">
            <div className="tickets-container">
              <h2 className="finanaceTrackTitle" onClick={() => toggleToList()}>
                <img className="arrowleft" src={Arrow} alt="-" /> Finance Track
                Details
              </h2>
              <div className="filter-options filter-options-finance">
                <div className="userDetailsWrap">
                  <div className="userImg">
                    <img src={Person} alt="-" />
                  </div>
                  <div className="userDetails">
                    <h3 className="userNameHeading">
                      {userAdjustedData.current?.financeUser?.first_name +
                        " " +
                        userAdjustedData.current?.financeUser?.last_name}
                    </h3>
                    <p className="userEmailrow">
                      {userAdjustedData.current?.financeUser?.email}
                    </p>
                    <p className="userPhonerow">
                      {userAdjustedData.current?.financeUser?.phone}
                    </p>
                    <p className="userRegisterFromrow">
                      Register from:{" "}
                      <strong>
                        {new Date(
                          userAdjustedData.current?.financeUser?.created_at
                        ).toLocaleDateString("en-US")}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-options">
              <div className="filter__option_col_wrap">
                <div className="filter__option_col">
                  <h6>Start Date</h6>
                  <input
                    type="date"
                    value={detailstartDateFilter}
                    onChange={(e) => setdetailStartDateFilter(e.target.value)}
                  />
                </div>
                <div className="filter__option_col">
                  <h6>End Date</h6>
                  <input
                    type="date"
                    value={detailendDateFilter}
                    onChange={(e) => setdetailEndDateFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Cash Vendor</th>
                    <th>Phone Number</th>
                    <th>Amount Approved</th>
                    <th>Ref No.</th>
                    <th>Attachments</th>
                    <th className="max-w-442">Note</th>
                  </tr>
                </thead>
                <tbody>
                  {userAdjustedData?.current?.adjustments_with_images?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {new Date(item.date).toLocaleDateString("en-US")}
                        </td>
                        <td>{item.cash_vendor}</td>
                        <td>{item.cash_vendor_phone}</td>
                        <td>{item.amount}</td>
                        <td>{item.reference}</td>
                        <td>
                          <div style={{ width: "100px", height: "100px" }}>
                            <ModalImage
                              small={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0].file_name}`}
                              large={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0].file_name}`}
                              alt={item?.cash_vendor}
                            />
                          </div>
                        </td>
                        <td>{item.note}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="page__container_wrapper ticket__page">
            <div className="tickets-container">
              <div className="filter-options">
                <h2>Finance Track</h2>

                <div className="filter__option_col_wrap">
                  {/* <div className='filter__option_col'>
                    <h6>Finance User</h6>
                    <select
                      value={financeUser}
                      onChange={(e) => setFinanceUser(e.target.value)}
                      className='form-select'
                    >
                      <option value="All">All </option>
                      <option value="Chad Lucas">Chad Lucas</option>
                      <option value="Chris Porter">Chris Porter</option>
                      <option value="Rose Banks">Rose Banks</option>
                    </select>
                  </div> */}
                  <div className="filter__option_col">
                    <h6>Start Date</h6>
                    <input
                      type="date"
                      value={startDateFilter}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="filter__option_col">
                    <h6>End Date</h6>
                    <input
                      type="date"
                      value={endDateFilter}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Finance User</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>No. Of Transactions</th>
                    <th>Amount Approved</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {financeTracks?.users?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => toggleDetails(item)}
                      className="fininaceRow"
                    >
                      <td>{index + 1}</td>
                      <td>
                        <span>{item.first_name + " " + item.last_name}</span>
                      </td>
                      <td>{item.phone}</td>
                      <td>{item.email}</td>
                      <td>{item.no__of_transactions}</td>
                      <td>{item.amount_approved}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinanceTrack;
